.loginForm {
  /* min-width: 400px; */
}

.header {
  padding-bottom: var(--space-12);
  display: grid;
  grid-row-gap: var(--space-4);
}

.forgotPassword {
  float: right;
}

.singUp {
  display: flex;
  justify-content: center;
  padding-top: var(--space-16);
}

.signUpLink {
  padding-left: var(--space-12);
}

.loginForm :global(.ant-form-item) {
  margin-bottom: var(--space-16) !important;
}

.loginForm :global(.ant-form-item .ant-form-item-control-input-content input) {
  padding: var(--space-8) var(--space-12) !important;
}

.loginForm :global(.ant-form-item .ant-input-affix-wrapper) {
  padding: var(--space-8) var(--space-12) !important;
}

.loginForm :global(.ant-form-item .ant-input-affix-wrapper input) {
  padding: 0 !important;
}

.loginForm .forgotPasswordRow :global(.ant-form-item-control-input) {
  min-height: unset !important;
}

.loginForm .forgotPasswordRow:global(.ant-form-item) {
  margin-bottom: var(--space-28) !important;
}
