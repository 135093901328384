.flexField .ant-form-item-control-input-content {
  display: flex;
}

.sideNav .ant-tabs-tab {
  padding: 0 var(--space-20) !important;
  font-size: var(--font-size-10);
}
.sideNav .ant-tabs-tabpane {
  padding-left: 0 !important;
}

.sideNav .ant-tabs-ink-bar {
  display: none;
}
.sideNav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.sideNav .ant-tabs-tab-btn:hover {
  color: var(--text500) !important;
  text-shadow: inherit !important;
}

.ant-table-tbody > tr > td,
.ant-table tfoot > tr > td {
  padding: var(--space-12) !important;
}
.ant-table-thead > tr > th,
.ant-table tfoot > tr > th {
  padding: var(--space-8) !important;
}

.ant-table-thead > tr > th,
.ant-table tfoot > tr > th {
  font-size: var(--font-size-13) !important;
}

.ant-table-thead > tr > th {
  background: var(--bg100) !important;
  border-bottom: 2px solid var(--bg300) !important;
  color: var(--text500) !important;
  font-weight: 600 !important;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 4px !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 4px !important;
}

.ant-table-tbody {
  color: var(--textbase) !important;
}

.ant-table {
  background: var(--bg) !important;
  box-shadow: var(--shadow-2dp) !important;
  font-size: var(--font-size-14) !important;
  font-family: var(--font-family) !important;
  border-radius: 4px !important;
}

.ant-collapse-borderless {
  background-color: var(--bg) !important;
}

.indexing-collapse .indexing-panel,
.indexing-collapse .indexing-panel {
  background: var(--bg);
  background-color: var(--bg);
  border-radius: 4px;
  margin-bottom: var(--space-12);
  border: 0px;
  overflow: hidden;
}

.ant-collapse .ant-collapse-borderless .indexing-collapse {
  background: var(--bg) !important;
  background-color: var(--bg) !important;
}

.ant-collapse-borderless > .ant-collapse-item.indexing-panel {
  border: 1px solid #dfe4ed;
  border-radius: 4px;
}

.ant-collapse-borderless
  > .ant-collapse-item.ant-collapse-item-active.indexing-panel {
  border: none;
  border-radius: 4px;
  background-color: var(--blue200);
}

.collectionMenu .ant-menu-item-selected {
  background-color: var(--blue100) !important;
}

.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  border-left: 3px solid var(--blue500) !important;
  border-right: unset !important;
  left: 0;
  transform: none !important;
}

.collectionMenu .ant-menu-item-selected::after {
  left: 0;
  border-left: 3px solid var(--blue500) !important;
  border-right: unset !important;
  transform: none !important;
}

.ant-page-header-heading-title {
  font-size: inherit !important;
  line-height: inherit !important;
}

/* button override */
.ant-btn-primary {
  background: var(--blue500) !important;
  border-color: var(--blue500) !important;
  border-radius: 4px !important;
}
.ant-btn-primary:disabled{
  background: var(--bg300) !important;
  border-color: var(--bg300) !important;
  color: var(--bg100) !important;
}

/* input override */
.ant-input-affix-wrapper,
.ant-input {
  border-radius: 4px !important;
  border: 1px solid var(--bg400) !important;
}

.ant-input-affix-wrapper:focus-within,
.ant-input:focus {
  /* border-color: var(--blue500)!important; */
  border: 1px solid var(--blue500) !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper > input.ant-input {
  border: none !important;
}

.ant-input-affix-wrapper > input.ant-input:focus {
  border: none !important;
  box-shadow: none !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-right-width: unset !important;
}
/* ant form item */

.addFieldsForm .ant-form-item {
  margin-bottom: 0 !important;
}

/* search field */
.addSearchFieldsForm {
  padding-top: var(--space-12) !important;
}
.addSearchFieldsForm .ant-form-item {
  margin-bottom: var(--space-16) !important;
}

.searchFieldList .ant-form-item {
  margin-bottom: var(--space-8) !important;
}

/* synonymsform */
.addSynonymsForm {
  max-width: 40rem;
}

/* ant slider overrider */

.ant-slider-rail {
  background-color: #cfdfff !important;
}
.ant-slider-track {
  background-color: #754aed !important;
}
.ant-slider-handle {
  width: 16px !important;
  height: 16px !important;
  margin-top: -6px !important;
  background-color: #fff;
  border: solid 2px #754aed !important;
  box-shadow: 0;
}
.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
  border-color: #46a6ff;
  box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
}
.ant-slider-handle:focus {
  border-color: #46a6ff;
  outline: none;
  box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #1890ff;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #69c0ff;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #69c0ff;
}

.custom-toast-message {
  /* position: relative;
  right: -50%; */
}

/* tabs content  */

.ant-tabs-content-holder {
  overflow-y: auto;
}

/* delete modal */
.delete-modal .ant-modal-content {
  border-radius: 8px;
}
.delete-modal .ant-modal-content .ant-modal-body {
  padding: var(--space-24);
}
.delete-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-title {
  font-size: var(--font-size-20);
}
.delete-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-content {
  font-size: var(--font-size-13);
}
.delete-modal .delete-modal-ok {
  background: var(--red500) !important;
  color: var(--bg);
}

/* side drawer with footer */

.drawer-with-footer .ant-drawer-header {
  padding: var(--space-16) var(--space-20);
}
.drawer-with-footer .ant-drawer-close {
  padding: 0 !important;
}
.drawer-with-footer .ant-drawer-close span {
  background: var(--bg200);
  border-radius: 50%;
  height: var(--space-32);
  width: var(--space-32);
  line-height: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--space-12) var(--space-16);
}
.drawer-with-footer .ant-drawer-body {
  padding: var(--space-20);
}
.drawer-with-footer .ant-drawer-footer {
  padding: 0;
}

/* create engine modal */
.create-engine-modal .ant-modal-content {
  border-radius: 8px;
}

.create-engine-modal .ant-modal-footer {
  padding: 0;
}

.card-wrapper {
  width: min-content;
  transform: scale(0.75);
}

/* sidemennu */
.sideMenu {
  width: 23rem;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  padding: var(--space-12) var(--space-16) !important;
  color: var(--textbase) !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 4px !important;
  border: 1px solid var(--bg400) !important;
}

.ant-select.ant-select-borderless .ant-select-selector {
  border: none !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none !important;
  border-color: var(--blue500) !important;
}

/* ant side menu  */
.sideMenu .ant-menu-item {
  color: var(--text500);
}
.sideMenu .ant-menu-item:hover {
  color: var(--blue500);
}
.sideMenu .ant-menu-item:hover svg g {
  fill: var(--blue500);
}
.sideMenu .ant-menu-item.ant-menu-item-selected {
  background: var(--blue100);
  color: var(--blue500);
}

.accountMenu .ant-menu-item:hover svg g {
  fill: var(--blue500);
}

.ant-form-item,
.ant-input {
  color: var(--textbase);
  font-weight: 500;
}

/* modal spacing */
.ant-modal .ant-modal-header,
.ant-modal .ant-modal-footer {
  padding: var(--space-12) var(--space-20);
}

.ant-modal .ant-modal-header {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.ant-modal .ant-modal-close .ant-modal-close-x {
  background: var(--bg200);
  border-radius: 50%;
  height: var(--space-32);
  width: var(--space-32);
  line-height: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--space-12) var(--space-16);
}

.ant-modal .ant-modal-body {
  padding: var(--space-20);
}

.sourceFormModal.ant-modal .ant-modal-body {
  padding: 0;
}

a {
  color: var(--blue500) !important;
}

/* select */
.ant-select-dropdown .ant-select-item.ant-select-item-option {
  padding: var(--space-12) var(--space-16) !important;
}


.ant-menu-item:hover{
  color: var(--blue500) !important;
}
.ant-menu-item-selected{
  color: var(--blue500) !important;
}

/* ant radio override */

.ant-radio-checked .ant-radio-inner{
  border-color: var(--blue500)!important;
}
.ant-radio-checked .ant-radio-inner::after{
  background-color: var(--blue500)!important;
}

/* ant checkbox override */
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: var(--blue500)!important;
  border-color: var(--blue500)!important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after{
  background-color: var(--blue500)!important;
}