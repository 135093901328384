.select {
  display: flex !important;
  padding: var(--space-8) 0 !important;
  /* border-bottom: 1px solid var(--bg300); */
}

.selectSeparator {
  background: var(--bg300);
  height: 1px;
  margin: 0 var(--space-12) var(--space-4) var(--space-12);
}

.configMenu {
  padding: var(--space-12) var(--space-16);
}
.separator {
  border-top: 1px solid var(--bg300);
  height: var(--space-4) !important;
}
.collectionIcon {
  padding-right: var(--space-12);
}

.select :global(.ant-select-selector) {
  padding: 0 var(--space-16) !important;
}

.selectItem {
  display: flex;
}

.menuItem {
  display: flex;
  font-family: var(--font-family);
  font-size: var(--font-size-14);
  font-weight: 500;
  padding-left: var(--space-16) !important;
}
