.sideMenu{
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto min-content auto;
    background-color: var(--bg);
    box-shadow: var(--shadow-4dp);
    z-index: 0;
}

.parent {
    display: grid;    
    grid-auto-rows: min-content;
    width: calc(32px + var(--space-20) + var(--space-20));
}

.separator{
    height: 100%;
    width: 1px;
    background: var(--bg300);
}

.content{
    display: grid;
}

.tabIcon{
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 32px;
    grid-row-gap: var(--space-4);
    font-family: var(--font-family) !important;
    font-weight: 600;
}

.tabFirstIcon {
    padding-top: var(--space-12);
}

.tabIcon .icon {
    padding: var(--space-8);
    background: var(--bg200);
    border-radius: 4px;
    height: 4rem;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
}

.tabIcon .icon.activeTab {
    background: var(--blue500);
}