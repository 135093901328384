.setupPage{
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: var(--bg200);
}
.content{
    display: grid;
    /* max-width: 40rem; */
    background: var(--bg);
    border-radius: 8px;
    padding: var(--space-24);
}
