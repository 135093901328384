:global(.ant-input){
    font-family: var(--font-family);
}

:global(.ant-input-affix-wrapper) {
    padding: var(--space-6) var(--space-12);
}

:global(input.ant-input){
    padding: var(--space-6) var(--space-12);
}