:global(.ant-btn) {
  border-radius: 4px;
  font-weight: 500;
  color: var(--textbase);
  border-color: #c0ccdb;
  font-family: var(--font-family);
}
:global(.ant-btn:disabled) {
  color: var(--text200);
}
:global(.ant-btn:hover:disabled) {
  color: var(--text200);
}

:global(.ant-btn.ant-btn-primary) {
  color: var(--white);
}

:global(.ant-btn.ant-btn-sm) {
  font-size: var(--font-size-13);
  padding: var(--space-4) var(--space-12);
  height: unset;
}

:global(.ant-btn:hover),
:global(.ant-btn:focus),
:global(.ant-btn:active) {
  color: var(--blue500);
  border-color: var(--blue500);
}
:global(.ant-btn.ant-btn-primary:hover),
:global(.ant-btn.ant-btn-primary:focus),
:global(.ant-btn.ant-btn-primary:active) {
  color: var(--white);
}

:global(.ant-btn.ant-btn-link) {
  border: none;
  color: var(--blue500);
}

:global(.ant-btn.ant-btn-link:disabled) {
  color: var(--text200);
}
