.headerContent {
  display: grid;
  align-items: flex-end;
  text-align: left;
  grid-row-gap: var(--space-4);
}

.forgotPasswordForm .contentRow:global(.ant-form-item) {
  margin-bottom: var(--space-28) !important;
}

.forgotPasswordForm .emailRow:global(.ant-form-item) {
  margin-bottom: var(--space-20) !important;
}

.forgotPasswordForm .buttonRow:global(.ant-form-item) {
  margin-bottom: var(--space-32) !important;
}

.forgotPasswordForm
  :global(.ant-form-item .ant-form-item-control-input-content input) {
  padding: var(--space-8) var(--space-12) !important;
}

.forgotPwdSccuess{
  display: grid;
  grid-row-gap: var(--space-8);
  justify-content: center;
}
.forgotPwdSccuess a{
  padding-top: var(--space-16);
}