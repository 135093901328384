/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import "./shared/styles/reset.css";
@import "./shared/styles/variable.css";
@import "./shared/styles/font.css";
@import "./shared/styles/override.css";
@import "./shared/styles/scrollbar.css";
@import "../node_modules/react-vis/dist/style.css";

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  font-size: 10px !important;
}
.ant-spin-nested-loading.mainLoader,
.ant-spin-nested-loading.mainLoader .ant-spin-container {
  width: 100%;
  height: 100%;
}

body {
  font-family: var(--font-family);
}

.text-center {
  text-align: center;
}

#create_field_setting_form_field_name:read-only {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}

#create_field_setting_form input.readonlyTextField{
  color: rgba(0, 0, 0, 0.25) !important;
  background: #f5f5f5 !important;
  cursor: not-allowed !important;
}