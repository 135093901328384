:root {
  /* primary blue */
  --blue600: #20318b;
  --blue500: #485fd5;
  --blue400: #aab4eb;
  --blue300: #ced4f4;
  --blue200: #e7eaf9;
  --blue100: #f3f4fc;

  /* secondary green */
  --green600: #1b5914;
  --green500: #32a626;
  --green400: #a3e89b;
  --green300: #c1f0bd;
  --green200: #e0f7de;
  --green100: #f0fbee;

  /* secondary red */
  --red600: #921a17;
  --red500: #e03e3a;
  --red400: #f1a5a3;
  --red300: #f7ccca;
  --red200: #fbe5e5;
  --red100: #fdf2f2;

  /* secondary magenta */
  --magneta600: #82135f;
  --magneta500: #e137ac;
  --magneta400: #f7cae9;
  --magneta300: #f9d7ee;
  --magneta200: #fbe4f4;
  --magneta100: #fdf2f9;

  /* secondary orange */
  --orange600: #c53800;
  --orange500: #fa733e;
  --orange400: #ffba9e;
  --orange300: #ffcbb7;
  --orange200: #ffe5db;
  --orange100: #fff6f3;

  /* secondary yellow */
  --yellow600: #916700;
  --yellow500: #f2ac00;
  --yellow400: #ffe094;
  --yellow300: #ffeab8;
  --yellow200: #fff5db;
  --yellow100: #fffaed;

  /* bg */
  --bg: #ffffff;
  --white: #ffffff;
  --bg400: #c2cbdc;
  --bg300: #dfe4ed;
  --bg200: #ecf1f8;
  --bg100: #f5f8fb;

  /* bg gray */
  --gray: #081c3d;
  --gray1: #081c3d;

  /* text color */
  --textbase: #081c3d;
  --text500: #2d3e5a;
  --text400: #6b778c;
  --text300: #9ca4b1;
  --text200: #c1c6ce;
  --text100: #e1e3e7;
  --text-link: #485fd6;
  --text-inverse: #fffffa;

  /* icon color */
  --icon: #081c3d;
  --icon-dark: #394964;
  --icon-medium: #6B778B;
  --icon-light: #ced2d8;
  --icon-inverse: #fffffe;


  /* box shadow */
  --shadow-2dp: 0 1px 4px 0 rgba(194, 203, 220, 0.6);
  --shadow-4dp: 0 2px 5px 0 rgba(194, 203, 220, 0.6);
  --shadow-8dp: 0 2px 5px 0 #c2cbdc;
  --shadow-16dp: 0 5px 12px 0 rgba(194, 203, 220, 0.7);
  --shadow-top: 0 -1px 4px 0 rgba(194, 203, 220, 0.6);
  --shadow-slide: -4px 2px 8px 0 rgba(107, 119, 140, 0.3);
  --shadow-overlay: 0 4px 20px 0 #6b778c;

  /* spacing */
  --space-1: 0.1rem;
  --space-2: 0.2rem;
  --space-4: 0.4rem;
  --space-6: 0.6rem;
  --space-8: 0.8rem;
  --space-10: 1rem;
  --space-12: 1.2rem;
  --space-14: 1.4rem;
  --space-16: 1.6rem;
  --space-20: 2rem;
  --space-24: 2.4rem;
  --space-28: 2.8rem;
  --space-32: 3.2rem;
  --space-36: 3.6rem;
  --space-40: 4rem;
  --space-48: 4.8rem;
  --space-64: 6.4rem;

  /* font size */
  --font-size-10: 1rem;
  --font-size-11: 1.1rem;
  --font-size-12: 1.2rem;
  --font-size-13: 1.3rem;
  --font-size-14: 1.4rem;
  --font-size-16: 1.6rem;
  --font-size-18: 1.8rem;
  --font-size-20: 2rem;
  --font-size-24: 2.4rem;
  --font-size-28: 2.8rem;
  --font-size-36: 3.6rem;

  /* font weight */
  --light: 400;
  --medium: 500;
  --bold: 600;

  /* font family */
  --font-family: "Inter", sans-serif;


  /* ant select contaier */
  --select-height: 36px;
}
